import { theme as chakraUiTheme, extendTheme } from '@chakra-ui/react'

import {
	borderRadius,
	breakpoints,
	colors,
	config,
	global,
	shadows,
	sizes,
	textStyles,
	typography
} from './base'
import {
	buttonTheme,
	calendarTheme,
	checkboxTheme,
	containerTheme,
	dividerTheme,
	dropdownTheme,
	headingTheme,
	inputTheme,
	linkTheme,
	modalTheme,
	popoverTheme,
	radioTheme,
	selectTheme,
	sliderTheme,
	switchTheme,
	tooltipTheme,
	wwtSelectTheme
} from './components'

/**
 * Fix bug with auto add `lg` breakpoint
 *
 *https://github.com/chakra-ui/chakra-ui/issues/4813#issuecomment-939194128
 */
// @ts-expect-error Chakra UI bug
chakraUiTheme.breakpoints = undefined

export const theme = extendTheme({
	styles: {
		global
	},
	config: config,
	components: {
		Switch: switchTheme,
		Checkbox: checkboxTheme,
		Container: containerTheme,
		Calendar: calendarTheme,
		Popover: popoverTheme,
		Modal: modalTheme,
		Button: buttonTheme,
		Tooltip: tooltipTheme,
		Radio: radioTheme,
		Slider: sliderTheme,
		Divider: dividerTheme,
		Dropdown: dropdownTheme,
		Link: linkTheme,
		Select: selectTheme,
		Input: inputTheme,
		WwtSelect: wwtSelectTheme,
		Heading: headingTheme
	},
	breakpoints,
	sizes,
	colors,
	...typography,
	textStyles,
	shadows,
	radii: borderRadius
})
