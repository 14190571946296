import type { ReactHTML } from 'react'

import { HTMLChakraProps, chakra, forwardRef } from '@chakra-ui/react'
import { HTMLMotionProps, Variant, motion } from 'framer-motion'

import { useDropdown } from './useDropdown'

type MotionVariants = Partial<Record<'enter' | 'exit', Variant>>

type HTMLMotionChakraProps<T extends keyof ReactHTML> = Omit<
	HTMLChakraProps<T>,
	keyof HTMLMotionProps<T>
> &
	Omit<
		HTMLMotionProps<T>,
		| 'style'
		| 'onDrag'
		| 'onDragEnd'
		| 'onDragStart'
		| 'onAnimationStart'
		| 'variants'
		| 'transition'
		| 'children'
	> & {
		variants?: MotionVariants
	}

const mergeVariants = (variants?: MotionVariants) => {
	if (!variants) return
	return {
		enter: {
			...variants.enter,
			visibility: 'visible'
		},
		exit: {
			...variants.exit,
			transitionEnd: {
				visibility: 'hidden'
			}
		}
	}
}

const scaleFade: MotionVariants = {
	exit: {
		opacity: 0,
		scale: 0.95,
		transition: {
			duration: 0.1,
			ease: [0.4, 0, 1, 1]
		}
	},
	enter: {
		scale: 1,
		opacity: 1,
		transition: {
			duration: 0.15,
			ease: [0, 0, 0.2, 1]
		}
	}
}

const MotionList = chakra(motion.ul)

export type DropdownTransitionProps = HTMLMotionChakraProps<'ul'>

export const DropdownTransition = forwardRef<DropdownTransitionProps, 'ul'>(
	(props, ref) => {
		const { variants = scaleFade, ...rest } = props
		const { isOpen } = useDropdown()

		return (
			<MotionList
				ref={ref}
				variants={mergeVariants(variants)}
				initial={false}
				animate={isOpen ? 'enter' : 'exit'}
				{...rest}
			/>
		)
	}
)
DropdownTransition.displayName = 'DropdownTransition'
