import { selectAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(parts.keys)

const sizes = {
	sm: definePartsStyle({
		field: {
			h: '2.5rem' // 40px
		}
	}),
	md: definePartsStyle({
		field: {
			h: '3rem' // 48px
		}
	}),
	lg: definePartsStyle({
		field: {
			h: '4rem' // 64px
		}
	})
}

export const selectTheme = defineMultiStyleConfig({
	sizes
})
