import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

import { textStyles } from '../base/textStyles'

export const headingTheme = defineStyleConfig({
	baseStyle: {
		...textStyles['headline-2'],
		lineHeight: 1.21
	},
	sizes: {
		xl: {
			...textStyles['headline-1']
		},
		lg: {
			...textStyles['headline-2']
		},
		md: {
			...textStyles['headline-3']
		},
		sm: {
			...textStyles['headline-4']
		}
	},
	variants: {
		active: defineStyle({
			color: 'brand.100'
		})
	},
	defaultProps: {
		size: 'lg'
	}
})
