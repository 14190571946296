import { IconComponent } from '@wwt/entities/base'
import { featureIcons } from '@wwt/shared/config/featureIcons'

export const getFeatureIcon = (
	groupIconName: string | null | undefined = null,
	featureIconName: string | null | undefined = null,
	optionIconName: string | null | undefined = null
): IconComponent => {
	if (optionIconName !== '' && optionIconName != null) {
		if (optionIconName in featureIcons) {
			return featureIcons[optionIconName]
		}
	}

	if (featureIconName !== '' && featureIconName != null) {
		if (featureIconName in featureIcons) {
			return featureIcons[featureIconName]
		}
	}

	if (groupIconName !== '' && groupIconName != null) {
		if (groupIconName in featureIcons) {
			return featureIcons[groupIconName]
		}
	}

	return featureIcons.FALLBACK
}
