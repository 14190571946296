import { radioAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { defineMultiStyleConfig, definePartsStyle } =
	createMultiStyleConfigHelpers(parts.keys)

const baseStyleControl = defineStyle({
	bg: 'white',
	borderColor: 'gray.500',
	_checked: {
		bg: 'white',
		borderWidth: '0.3125rem',
		borderColor: 'gray.500',
		_hover: null
	},
	_disabled: {
		bg: 'white',
		borderWidth: '0.3125rem',
		borderColor: 'gray.200'
	}
})

const baseStyleContainer = defineStyle({
	_hover: {
		'>.chakra-radio__control': {
			bg: 'gray.100'
		}
	}
})

const sizes = {
	md: definePartsStyle({
		control: { w: '1.125rem', h: '1.125rem', m: '0.1875rem' }
	})
}

export const radioTheme = defineMultiStyleConfig({
	baseStyle: {
		container: baseStyleContainer,
		control: baseStyleControl
	},
	sizes
})
