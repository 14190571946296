export const featureHasOnlyOneSelectedOption = <
	T extends { isSelected: boolean }
>(
	options: T[]
) => {
	const countSelectedOptions = options.reduce((acc, option) => {
		const optionIsSelected = option.isSelected ? 1 : 0
		return acc + optionIsSelected
	}, 0)

	return countSelectedOptions === 1
}
