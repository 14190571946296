import { checkboxAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(parts.keys)

const baseStyleControl = defineStyle({
	borderColor: 'gray.500',
	borderWidth: '0.125rem',
	_disabled: {
		bg: 'gray.200',
		color: 'gray.200',
		borderColor: 'gray.200',
		_hover: {
			bg: 'gray.200'
		}
	}
})

const baseStyleContainer = defineStyle({
	_hover: {
		// how to get proper class name?
		'>.chakra-checkbox__control': {
			bg: 'gray.100',
			_checked: {
				bg: 'gray.400'
			}
		}
	}
})

const sizes = {
	md: definePartsStyle({
		control: {
			w: '1.25rem',
			h: '1.25rem',
			m: '0.125rem',
			borderRadius: '0.125rem'
		},
		icon: { strokeWidth: '0.0625rem!important' } // bypassing inline styles
	})
}

export const checkboxTheme = defineMultiStyleConfig({
	baseStyle: {
		container: baseStyleContainer,
		control: baseStyleControl
	},
	sizes,
	defaultProps: {
		colorScheme: 'gray'
	}
})
