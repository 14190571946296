import {
	createMultiStyleConfigHelpers,
	cssVar,
	defineStyle
} from '@chakra-ui/react'

import { inputTheme } from './inputTheme'

const { defineMultiStyleConfig, definePartsStyle } =
	createMultiStyleConfigHelpers(['container', 'field', 'icon', 'list', 'item'])

const $bg = cssVar('select-bg')
const $menuBg = cssVar('menu-bg')
const $menuShadow = cssVar('menu-shadow')

const baseStyleContainer = defineStyle({
	// some styling for container
})

const baseStyleField = defineStyle({
	...inputTheme.baseStyle?.field,
	w: 'full',
	display: 'inline-flex',
	alignItems: 'center',
	appearance: 'none',
	pl: '1rem',
	pb: '0.0625rem',
	textAlign: 'left',
	bg: $bg.reference,
	[$bg.variable]: 'colors.white',
	_active: inputTheme.variants?.default.field._focusVisible
})

const baseStyleIcon = defineStyle({
	transitionProperty: 'transform',
	transitionDuration: 'normal',
	color: 'currentColor',
	boxWidth: '1.5rem'
})

const baseStyleList = defineStyle({
	[$menuBg.variable]: 'colors.white',
	[$menuShadow.variable]: 'shadows.md',
	color: 'inherit',
	minW: '13rem',
	py: '0.25rem',
	zIndex: 'dropdown',
	overflow: 'hidden',
	borderRadius: 'md',
	borderWidth: '0.0625rem',
	bg: $menuBg.reference,
	boxShadow: $menuShadow.reference,
	position: 'absolute',
	mx: '1rem',
	roundedTop: '0',
	borderTop: '0',
	transformOrigin: 'top'
})

const baseStyleItem = defineStyle({
	py: '0.75rem',
	px: '1rem',
	transitionProperty: 'background',
	transitionDuration: 'ultra-fast',
	transitionTimingFunction: 'ease-in',
	_focus: {
		[$menuBg.variable]: 'colors.gray.100'
	},
	_hover: {
		[$menuBg.variable]: 'colors.gray.100'
	},
	_selected: {
		[$menuBg.variable]: 'colors.white',
		borderColor: 'brand.200',
		borderInlineWidth: '0.125rem',
		borderBlockWidth: '0.0625rem',
		py: '0.6875rem',
		px: '0.875rem'
	},
	_expanded: {
		[$menuBg.variable]: 'colors.gray.100'
	},
	_disabled: {
		color: 'gray.200',
		cursor: 'not-allowed'
	},
	bg: $menuBg.reference,
	cursor: 'pointer'
})

const baseStyle = definePartsStyle({
	container: baseStyleContainer,
	field: baseStyleField,
	icon: baseStyleIcon,
	list: baseStyleList,
	item: baseStyleItem
})

const iconSpacing = defineStyle({
	paddingInlineEnd: '1rem'
})

const sizes = {
	lg: {
		...inputTheme.sizes?.lg,
		field: {
			...inputTheme.sizes?.lg.field,
			...iconSpacing
		}
	},
	md: {
		...inputTheme.sizes?.md,
		field: {
			...inputTheme.sizes?.md.field,
			...iconSpacing
		}
	},
	sm: {
		...inputTheme.sizes?.sm,
		field: {
			...inputTheme.sizes?.sm.field,
			...iconSpacing
		}
	}
}

export const wwtSelectTheme = defineMultiStyleConfig({
	baseStyle,
	sizes,
	variants: inputTheme.variants,
	defaultProps: inputTheme.defaultProps
})
