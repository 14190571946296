import {
	type BookableNotPackagedFeatureDto,
	type BookableNotPackagedFeatureOptionDto,
	type BookablePackageDto,
	type BookablePackagedFeatureDto,
	type BookablePackagedFeatureOptionDto,
	type CheckInFeatureDto,
	type CheckInFeatureOptionDto,
	type HotelDynamicDataDto,
	type HotelStaticDataDto,
	type ImageDto,
	type IncludedFeatureDto,
	type IncludedFeatureOptionDto,
	type OfferMetaDataDto,
	type RoomDynamicDataDto,
	type RoomStaticDataDto
} from './autogenerated/autogeneratedTypes.ts'

type ModifyField<T, K extends keyof T, V> = {
	[P in keyof T]: P extends K ? V : T[P]
}

export type Image = ImageDto & {
	id: string
}

export enum FeatureType {
	'bookablePackaged' = 'bookablePackaged',
	'bookableNotPackaged' = 'bookableNotPackaged',
	'checkIn' = 'checkIn',
	'included' = 'included'
}

export type BookableNotPackagedFeature = BookableNotPackagedFeatureDto & {
	type: FeatureType.bookableNotPackaged
}
export type BookableNotPackagedFeatureOption =
	BookableNotPackagedFeatureOptionDto

export type BookablePackagedFeature = BookablePackagedFeatureDto & {
	type: FeatureType.bookablePackaged
}
export type BookablePackagedFeatureOption = BookablePackagedFeatureOptionDto
export type BookablePackage = BookablePackageDto

export type CheckInFeature = CheckInFeatureDto & {
	type: FeatureType.checkIn
}
export type CheckInFeatureOption = CheckInFeatureOptionDto

export type IncludedFeature = IncludedFeatureDto & {
	type: FeatureType.included
}
export type IncludedFeatureOption = IncludedFeatureOptionDto

export type HotelStatic = ModifyField<
	ModifyField<HotelStaticDataDto, 'images', Image[]>,
	'includedFeatures',
	IncludedFeature[]
>
export type HotelDynamic = ModifyField<
	HotelDynamicDataDto,
	'checkInFeatures',
	CheckInFeature[]
>
export type RoomStatic = ModifyField<
	ModifyField<RoomStaticDataDto, 'images', Image[]>,
	'includedFeatures',
	IncludedFeature[]
>
export type RoomDynamic = ModifyField<
	ModifyField<
		ModifyField<
			ModifyField<RoomDynamicDataDto, 'includedFeatures', IncludedFeature[]>,
			'checkInFeatures',
			CheckInFeature[]
		>,
		'bookableNotPackagedFeatures',
		BookableNotPackagedFeature[]
	>,
	'bookablePackagedFeatures',
	BookablePackagedFeature[]
>

export type OfferIdentifier = OfferMetaDataDto
