import { useRef } from 'react'

import { Box, BoxProps, useDisclosure, useOutsideClick } from '@chakra-ui/react'
import { useDesktopBreakpoint } from '@wwt/custom/chakra-ui'
import { wwtMemo } from '@wwt/custom/wwt'

import DollarIcon from '@wwt/shared/assets/icons/outline/dollar.svg'
import TipIcon from '@wwt/shared/assets/icons/outline/tip.svg'

import { TooltipIconType } from '../types/TooltipIconType.ts'
import { TooltipBaseContent } from './TooltipBaseContent.tsx'

interface TooltipsProps extends BoxProps {
	children: JSX.Element
	tooltipIconType?: TooltipIconType
}

export const TooltipBase = wwtMemo(
	({
		children,
		tooltipIconType = TooltipIconType.default,
		...props
	}: TooltipsProps) => {
		const { isOpen, onOpen, onClose, onToggle } = useDisclosure()
		const ref = useRef<HTMLDivElement>(null)

		useOutsideClick({
			ref: ref,
			handler: onClose
		})

		const TooltipIcon =
			tooltipIconType === TooltipIconType.dollar ? DollarIcon : TipIcon

		const isDesktop = useDesktopBreakpoint()

		/**
		 * Fix for disable close on click on desktop
		 */
		if (isDesktop) {
			return (
				<Box
					display="inline-block"
					marginTop="-0.40625" // -6.5px
					marginLeft="0.3125" // 5px
					{...props}
				>
					<TooltipBaseContent
						TooltipIcon={TooltipIcon}
						tooltipIconType={tooltipIconType}
						closeOnClick={false}
						{...props}
					>
						{children}
					</TooltipBaseContent>
				</Box>
			)
		}

		//FIXME: Maybe there are another way to fix this on mobile
		// Current fix related to this issue https://github.com/chakra-ui/chakra-ui/issues/7107#issuecomment-1364359979
		return (
			<Box
				ref={ref}
				display="inline-block"
				marginTop="-0.40625" // -6.5px
				marginLeft="0.3125" // 5px
				onMouseEnter={onOpen}
				onMouseLeave={onClose}
				onClick={onToggle}
				{...props}
			>
				<TooltipBaseContent
					TooltipIcon={TooltipIcon}
					tooltipIconType={tooltipIconType}
					isOpen={isOpen}
				>
					{children}
				</TooltipBaseContent>
			</Box>
		)
	}
)
