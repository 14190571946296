import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface State {
	/**
	 * Search response mocked value
	 * @value string - JSON
	 * @value null - empty and ignored
	 *
	 * Important: Empty value is valid value
	 *
	 * @author Ivan Shchedrovskyi
	 */
	searchResponseValue: string | null

	/**
	 * If true - we use value from this mock instead of make request to API
	 * If false - we make request to API
	 *
	 * @author Ivan Shchedrovskyi
	 */
	searchResponseUsed: boolean
}

interface Actions {
	setSearchValue: (searchResponse: string | null) => void
	toggleSearchUsed: () => void
}

/**
 * Store for save mocked server response
 *
 * @author Ivan Shchedrovskyi
 */
export const useLocalMock = create<State & Actions>()(
	persist(
		set => ({
			/* Search response */
			searchResponseValue: null,
			searchResponseUsed: false,

			toggleSearchUsed: () =>
				set(store => ({
					...store,
					searchResponseUsed: !store.searchResponseUsed
				})),
			setSearchValue: searchResponseValue =>
				set(store => ({
					...store,
					searchResponseValue
				}))
		}),
		{
			name: 'local-mock' // name of the item in the storage (must be unique)
			// by default, 'localStorage' is used
		}
	)
)
