import { DayPicker } from 'react-day-picker'

import {
	Box,
	StyleProps,
	forwardRef,
	useMultiStyleConfig
} from '@chakra-ui/react'
import { mapKeys } from 'radash'

import { Caption } from './Caption'
import { DayContent } from './DayContent'
import { IconLeft } from './IconLeft'
import { IconRight } from './IconRight'

export type CalendarProps = StyleProps & React.ComponentProps<typeof DayPicker>

export const Calendar = forwardRef<CalendarProps, typeof DayPicker>(
	({ components, ...props }, ref) => {
		const { container, ...styles } = useMultiStyleConfig('Calendar')

		return (
			<Box
				ref={ref}
				as={DayPicker}
				__css={{
					...container,
					...mapKeys(styles, key => `& .rdp-${key}`)
				}}
				components={{
					IconLeft,
					IconRight,
					DayContent,
					Caption
				}}
				{...props}
			/>
		)
	}
)
