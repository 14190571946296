import { useMemo } from 'react'

import { BoxProps } from '@chakra-ui/react'

import { Feature } from '@wwt/entities/search-response'
import { getSelectedOptionInfoFromFeature } from '@wwt/shared/utils/getSelectedOptionInfoFromFeature'

import { useFeatureLabel } from '../model/useFeatureLabel.tsx'
import { getFeatureTooltipInfo } from '../utils/getFeatureTooltipInfo.ts'
import { ComparisonFeature } from './ComparisonFeature.tsx'
import { Feature as FeatureComponent } from './Feature.tsx'

export interface FeatureByFeatureDataProps extends BoxProps {
	feature: Feature
	onClick?: () => void
	forceIsClickable?: boolean
	showSelectedOption?: boolean
	showFeatureName?: boolean
	disableIcon?: boolean
	comparisonMode?: boolean
}

export const FeatureByFeatureData = ({
	feature,
	onClick,
	forceIsClickable,
	showSelectedOption = true,
	showFeatureName = true,
	disableIcon = false,
	comparisonMode = false,
	...props
}: FeatureByFeatureDataProps) => {
	const selectedOption = useMemo(
		() => getSelectedOptionInfoFromFeature(feature),
		[feature]
	)

	const label = useFeatureLabel(
		selectedOption,
		feature,
		showSelectedOption,
		showFeatureName
	)

	const tooltipInfo = getFeatureTooltipInfo(
		feature,
		selectedOption,
		showSelectedOption,
		showFeatureName
	)

	if (comparisonMode) {
		return (
			<ComparisonFeature
				iconValues={{
					groupIconName: feature.group?.iconName,
					featureIconName: feature.iconName,
					optionIconName: selectedOption.iconName
				}}
				label={label}
				isClickable={
					forceIsClickable === undefined
						? 'options' in feature && feature.options.length > 1
						: forceIsClickable
				}
				isExist={selectedOption.isExist}
				onClick={onClick}
				disableIcon={disableIcon}
				tooltipInfo={tooltipInfo}
				{...props}
			/>
		)
	}

	return (
		<FeatureComponent
			iconValues={{
				groupIconName: feature.group?.iconName,
				featureIconName: feature.iconName,
				optionIconName: selectedOption.iconName
			}}
			label={label}
			isClickable={
				forceIsClickable === undefined
					? 'options' in feature && feature.options.length > 1
					: forceIsClickable
			}
			isExist={selectedOption.isExist}
			onClick={onClick}
			disableIcon={disableIcon}
			tooltipInfo={tooltipInfo}
			{...props}
		/>
	)
}
