import { BookablePackagedFeatureOptionDto } from '../../autogenerated/autogeneratedTypes.ts'

export const bookablePackagedOptionsValidator = (
	options: BookablePackagedFeatureOptionDto[]
): {
	path?: string[]
	message: string
}[] => {
	const result = []

	const allPackagesMap: Record<string, boolean> = {}

	for (const option of options) {
		if (option.packages.length < 1) {
			result.push({
				message: 'All options must have one or more packages'
			})

			continue
		}

		if (option.isSelected) {
			const selectedPackage = option.packages.find(
				optionPackage => optionPackage.isSelected === true
			)

			if (selectedPackage === undefined) {
				result.push({
					message: 'Selected option must have one selected package'
				})
				continue
			}
		}

		option.packages.forEach(optionPackage => {
			if (optionPackage.bookingKey in allPackagesMap) {
				if (
					optionPackage.isSelected !== allPackagesMap[optionPackage.bookingKey]
				) {
					result.push({
						message:
							'The same package is selected and not selected in several options at the same time'
					})
				}

				return
			}

			allPackagesMap[optionPackage.bookingKey] = optionPackage.isSelected
		})
	}

	const countOfSelectedPackages = Object.values(allPackagesMap).reduce(
		(acc, packageIsSelected) => {
			const isSelectedNumber = packageIsSelected ? 1 : 0
			return acc + isSelectedNumber
		},
		0
	)

	if (countOfSelectedPackages > 1) {
		result.push({
			message: 'Only one selected package must be included in the offer'
		})
	}

	return result
}
