import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
	Button,
	Center,
	CenterProps,
	Heading,
	Icon,
	Text,
	VStack
} from '@chakra-ui/react'

import MagnifyOutIcon from '@wwt/shared/assets/icons/outline/magnify-minus.svg'

interface NotFoundProps extends CenterProps {
	icon?: React.ReactNode
	title?: string
	text?: string
	button?: React.ReactNode
}

/**
 * Used for not found pages
 *
 * @author Vladyslav Vladinov
 */
export const NotFound = ({
	icon = (
		<Icon
			as={MagnifyOutIcon}
			boxSize="4rem"
		/>
	),
	...props
}: NotFoundProps) => {
	const { t } = useTranslation('not-found')

	const {
		title = t('pageNotFound.title'),
		text = t('pageNotFound.text'),
		button = (
			<Button
				as={Link}
				to="/"
				colorScheme="brand"
				w="full"
			>
				{t('pageNotFound.button')}
			</Button>
		),
		...centerProps
	} = props

	return (
		<Center {...centerProps}>
			<VStack
				spacing="1.5rem"
				maxW="25.5rem"
				w="full"
			>
				{icon}
				<VStack spacing="1rem">
					<Heading
						as="h1"
						textStyle="headline-2"
					>
						{title}
					</Heading>
					<Text>{text}</Text>
				</VStack>
				{button}
			</VStack>
		</Center>
	)
}
