import { sliderAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(sliderAnatomy.keys)

const baseStyle = definePartsStyle({
	track: {
		bg: 'gray.200'
	},
	filledTrack: {
		bg: 'black',
		transition: 'all 200ms cubic-bezier(.08,.82,.17,1)'
	},
	thumb: {
		bg: 'black',
		boxSize: { base: '1rem', md: '1.5rem' },
		transition: 'all 200ms cubic-bezier(.08,.82,.17,1)'
	},
	mark: {
		color: 'gray.500',
		fontSize: { base: '0.875rem', md: '1.25rem' },
		fontWeight: { base: '400', md: '500' },
		mt: { base: '-1rem', md: '-1.875rem' },
		transform: 'translateX(-50%) translateY(-100%)',
		transition: 'all 200ms cubic-bezier(.08,.82,.17,1)'
	}
})

const reversedTrackVariant = definePartsStyle({
	track: {
		bg: 'black'
	},
	filledTrack: {
		bg: 'gray.200'
	}
})

const noTransitionVariant = definePartsStyle({
	filledTrack: {
		transition: ''
	},
	thumb: {
		transition: ''
	},
	mark: {
		transition: ''
	}
})

export const sliderTheme = defineMultiStyleConfig({
	baseStyle,
	variants: {
		reversedTrack: reversedTrackVariant,
		noTransition: noTransitionVariant
	}
})
