import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const baseStyle = defineStyle({
	borderColor: '#AAA'
})

const offerConfigurationVariant = defineStyle({
	borderWidth: '0.125rem',
	borderStyle: 'solid'
})

const variants = {
	offerConfiguration: offerConfigurationVariant
}

export const dividerTheme = defineStyleConfig({
	baseStyle,
	variants
})
