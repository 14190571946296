import { Icon } from '@chakra-ui/react'

import ArrowLeftIcon from '@wwt/shared/assets/icons/outline/arrow-left.svg'

export const IconLeft = () => {
	return (
		<Icon
			as={ArrowLeftIcon}
			boxSize="1rem"
		/>
	)
}
