import { Box, HTMLChakraProps, defineStyle, forwardRef } from '@chakra-ui/react'
import { HTMLMotionProps } from 'framer-motion'

import {
	DropdownTransition,
	DropdownTransitionProps
} from './DropdownTransition'
import { useDropdown, useStyles } from './useDropdown'

type DropdownContentProps = DropdownTransitionProps & {
	rootProps?: HTMLChakraProps<'div'>
	motionProps?: HTMLMotionProps<'ul'>
}

export const DropdownList = forwardRef<DropdownContentProps, 'ul'>(
	({ rootProps, motionProps, ...listProps }, ref) => {
		const styles = useStyles()

		const listStyles = defineStyle({
			position: 'relative',
			display: 'flex',
			flexDirection: 'column',
			...styles.list
		})
		const { isOpen, getPopperProps } = useDropdown()

		return (
			<Box
				{...getPopperProps({
					...rootProps,
					style: {
						visibility: isOpen ? 'visible' : 'hidden',
						...rootProps?.style
					}
				})}
				__css={styles.popper}
				className="chakra-popover__popper"
			>
				<DropdownTransition
					{...motionProps}
					{...listProps}
					ref={ref}
					onAnimationComplete={listProps.onAnimationComplete}
					__css={listStyles}
				/>
			</Box>
		)
	}
)
DropdownList.displayName = 'DropdownMenu'
