import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface State {
	localLikesUsed: boolean
	localLikesValue: boolean
	initialLocalLikesUsed: boolean
	initialLocalLikesValue: boolean
}

interface Actions {
	toggleLocalLikesUsed: () => void
	toggleLocalLikesValue: () => void
	setLocalLikes: (value: boolean) => void
	restoreInitialValues: () => void
}

/**
 * Store for save local likes values
 *
 * We have four variables: used and value, and their initial values
 * If used = false, app use likes value from environment variables
 * If used = true, app use likes value from this store
 *
 * @author Vladyslav Vladinov
 */
export const useLocalLikes = create<State & Actions>()(
	persist(
		set => ({
			// Local likes enabled by default for test with mock data
			localLikesUsed: true,
			localLikesValue: true,

			// Local likes enabled by default for test with mock data
			initialLocalLikesUsed: true,
			initialLocalLikesValue: true,
			toggleLocalLikesUsed: () =>
				set(store => ({
					...store,
					localLikesUsed: !store.localLikesUsed,
					initialLocalLikesUsed: !store.localLikesUsed
				})),
			toggleLocalLikesValue: () =>
				set(store => ({
					...store,
					localLikesValue: !store.localLikesValue,
					initialLocalLikesValue: !store.localLikesValue
				})),
			setLocalLikes: value => {
				set(store => ({
					...store,
					localLikesValue: value,
					localLikesUsed: value
				}))
			},
			restoreInitialValues: () =>
				set(store => ({
					...store,
					localLikesUsed: store.initialLocalLikesUsed,
					localLikesValue: store.initialLocalLikesValue
				}))
		}),
		{
			name: 'local-likes-v2-01' // name of the item in the storage (must be unique)
			// by default, 'localStorage' is used
		}
	)
)
