import { DayContentProps, useDayPicker } from 'react-day-picker'

import { Box } from '@chakra-ui/react'

export const DayContent = (props: DayContentProps) => {
	const {
		locale,
		formatters: { formatDay }
	} = useDayPicker()

	// using class name here to style this component from calendar theme
	return (
		<Box className="rdp-day_content">{formatDay(props.date, { locale })}</Box>
	)
}
