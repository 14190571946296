/**
 * All integrations we support for set in admin
 *
 * Our application must support all types of integrations,
 * so this enum should not be used in a search request
 *
 * @author Ivan Shchedrovskyi
 */
export enum Integrations {
	FAKE = 'FAKE_INTEGRATION',
	HOTELBEDS = 'HOTELBEDS_INTEGRATION',
	RATEHAWK = 'RATEHAWK_INTEGRATION'
}
