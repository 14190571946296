import { z } from 'zod'

const protocolRegex = /^(http|https):\/\//

const urlSchema = z.string().url()

/**
 * Used to get valid url from string
 * @param url url to refine
 * @returns valid url or undefined
 */
export const refineUrl = (url?: string): string | undefined => {
	if (!url) return undefined

	const urlWithProtocol = url.match(protocolRegex) ? url : `http://${url}`

	try {
		return urlSchema.parse(urlWithProtocol)
	} catch (err) {
		return undefined
	}
}
