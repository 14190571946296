import {
	type BookablePackagedFeatureOptionDto,
	type RoomDynamicDataDto
} from '../../autogenerated/autogeneratedTypes.ts'
import { bookablePackagedOptionsValidator } from './bookablePackagedOptionsValidator.ts'
import { packageMapValidation } from './packageMapValidation.ts'

export const bookablePackagedPackagesValidator = (
	features: RoomDynamicDataDto['bookablePackagedFeatures']
) => {
	const result: {
		message: string
		path?: string[]
	}[] = []

	if (features?.length === 0 || !features) {
		return result
	}

	const allBookablePackagedFeatureOptions: BookablePackagedFeatureOptionDto[] =
		features?.reduce(
			(acc: BookablePackagedFeatureOptionDto[], feature) => [
				...acc,
				...(feature.options ?? [])
			],
			[]
		) ?? []

	result.push(
		/**
		 * Base option validation
		 */
		...bookablePackagedOptionsValidator(allBookablePackagedFeatureOptions),
		/**
		 * Extend packages validation
		 */
		...packageMapValidation(features)
	)

	return result
}
