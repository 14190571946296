import {
	ComponentProps,
	ComponentType,
	FunctionComponent,
	MemoExoticComponent,
	NamedExoticComponent,
	memo
} from 'react'
import isEqual from 'react-fast-compare'

/**
 * Type definitions of wwtMemo was copied from React.memo function
 */
function wwtMemo<P extends object>(
	Component: FunctionComponent<P>,
	propsAreEqual?: (prevProps: Readonly<P>, nextProps: Readonly<P>) => boolean
): NamedExoticComponent<P>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function wwtMemo<T extends ComponentType<any>>(
	Component: T,
	propsAreEqual?: (
		prevProps: Readonly<ComponentProps<T>>,
		nextProps: Readonly<ComponentProps<T>>
	) => boolean
): MemoExoticComponent<T>
// eslint-disable-next-line func-style,prefer-arrow/prefer-arrow-functions,@typescript-eslint/no-explicit-any
function wwtMemo(component: ComponentType<any>): MemoExoticComponent<any> {
	return memo(component, isEqual)
}

export { wwtMemo }
