import { As, Box, Icon, Tooltip, TooltipProps } from '@chakra-ui/react'
import { wwtMemo } from '@wwt/custom/wwt'

import { TooltipIconType } from '../types/TooltipIconType.ts'

interface TooltipBaseContentProps extends TooltipProps {
	children: JSX.Element
	tooltipIconType?: TooltipIconType
	TooltipIcon: As
}

/**
 * Component for show base tooltip content
 *
 * @author Ivan Shchedrovskyi
 */
export const TooltipBaseContent = wwtMemo(
	({
		children,
		tooltipIconType,
		TooltipIcon,
		...props
	}: TooltipBaseContentProps) => {
		return (
			<Tooltip
				label={children}
				maxW="20rem"
				maxH="29.375rem"
				openDelay={200}
				{...props}
			>
				<Box width="1rem">
					<Icon
						as={TooltipIcon}
						width="1rem"
						height="1rem"
						color={
							tooltipIconType === TooltipIconType.dollar ? 'brand.200' : 'gray'
						}
					/>
				</Box>
			</Tooltip>
		)
	}
)
