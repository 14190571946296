import { IconComponent } from '@wwt/entities/base'
import { checkUrlOnValid } from '@wwt/shared/utils/checkUrlOnValid/checkUrlOnValid.ts'

import { ToolTipWithIcon } from '../../Tooltips/ToolTipWithIcon'
import { TooltipWithPicture } from '../../Tooltips/TooltipWithPicture'

// TODO: Refactor
interface FeatureTooltipProps {
	imageUrl?: string | null
	iconComponent?: IconComponent
	title: string
	text?: string
}

export const FeatureTooltip = ({
	imageUrl,
	iconComponent,
	title,
	text
}: FeatureTooltipProps) => {
	if (imageUrl && checkUrlOnValid(imageUrl)) {
		return (
			<TooltipWithPicture
				img={imageUrl}
				title={title}
				text={text ?? ''}
			/>
		)
	}

	if (iconComponent) {
		return (
			<ToolTipWithIcon
				icon={iconComponent}
				title={title}
				text={text ?? ''}
			/>
		)
	}

	return null
}
