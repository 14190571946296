import AccommodationIcon from '@wwt/shared/assets/icons/features/accommodation.svg'
import BathroomIcon from '@wwt/shared/assets/icons/features/bathroom.svg'
import BedsIcon from '@wwt/shared/assets/icons/features/beds.svg'
import BusinessIcon from '@wwt/shared/assets/icons/features/business.svg'
import CancellationIcon from '@wwt/shared/assets/icons/features/cancellation.svg'
import EntertainmentIcon from '@wwt/shared/assets/icons/features/entertainment.svg'
import FacilitiesIcon from '@wwt/shared/assets/icons/features/facilities.svg'
import FallbackIcon from '@wwt/shared/assets/icons/features/fallback.svg'
import GeneralIcon from '@wwt/shared/assets/icons/features/general.svg'
import HealthIcon from '@wwt/shared/assets/icons/features/health.svg'
import LocationIcon from '@wwt/shared/assets/icons/features/location.svg'
import MealsIcon from '@wwt/shared/assets/icons/features/meals.svg'
import ParkingIcon from '@wwt/shared/assets/icons/features/parking.svg'
import PetsIcon from '@wwt/shared/assets/icons/features/pets.svg'
import PolicyIcon from '@wwt/shared/assets/icons/features/policy.svg'
import RoomIcon from '@wwt/shared/assets/icons/features/room.svg'
import SustainabilityIcon from '@wwt/shared/assets/icons/features/sustainability.svg'
import TransferIcon from '@wwt/shared/assets/icons/features/transfer.svg'
import ViewIcon from '@wwt/shared/assets/icons/features/view.svg'

// TODO: Import IconComponent from entities
export type IconComponent = React.FunctionComponent<
	React.SVGProps<SVGSVGElement>
>

type FeatureIcons = Record<string, IconComponent>

/**
 * Icons based on backend mapping
 *
 * TODO: Its a global icons for all elements, so need to rename
 */
export const featureIcons: FeatureIcons = {
	accommodation: AccommodationIcon,
	business: BusinessIcon,
	facilities: FacilitiesIcon,
	general: GeneralIcon,
	health: HealthIcon,
	location: LocationIcon,
	meals: MealsIcon,
	policy: PolicyIcon,
	entertainment: EntertainmentIcon,
	sustainability: SustainabilityIcon,
	transfer: TransferIcon,
	view: ViewIcon,
	bathroom: BathroomIcon,
	beds: BedsIcon,
	cancellation: CancellationIcon,
	parking: ParkingIcon,
	pets: PetsIcon,
	room: RoomIcon,

	FALLBACK: FallbackIcon
}
