export const responsiveHover = <T>(
	styles: T
): {
	'@media (hover: hover) and (pointer: fine)': {
		_hover: T
	}
} => ({
	'@media (hover: hover) and (pointer: fine)': {
		_hover: styles
	}
})
