import { createContext, useContext } from 'react'

import { createStylesContext } from '@chakra-ui/react'

import { DropdownContextProps } from '../types'

const [StylesProvider, useStyles] = createStylesContext('Dropdown')

const DropdownContext = createContext<DropdownContextProps | null>(null)

const useDropdown = () => {
	const context = useContext(DropdownContext)

	if (!context) {
		throw new Error('useDropdown must be used within a <Dropdown />')
	}

	return context
}

// eslint-disable-next-line react-refresh/only-export-components
export { StylesProvider, DropdownContext, useStyles, useDropdown }
