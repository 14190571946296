import { Box, TextProps, forwardRef } from '@chakra-ui/react'

import { useStyles } from './useDropdown'

export type DropdownGroupProps = TextProps

export const DropdownGroup = forwardRef<DropdownGroupProps, 'li'>(
	({ title, children, ...props }, ref) => {
		const styles = useStyles()

		return (
			<li
				ref={ref}
				role="group"
			>
				{title && (
					<Box
						as="p"
						{...props}
						__css={styles.groupTitle}
					>
						{title}
					</Box>
				)}
				<ul role="listbox">{children}</ul>
			</li>
		)
	}
)
DropdownGroup.displayName = 'DropdownGroup'
