import { menuAnatomy as parts } from '@chakra-ui/anatomy'
import {
	createMultiStyleConfigHelpers,
	cssVar,
	defineStyle
} from '@chakra-ui/react'

const { defineMultiStyleConfig, definePartsStyle } =
	createMultiStyleConfigHelpers(parts.keys)

const $bg = cssVar('menu-bg')
const $shadow = cssVar('menu-shadow')

const baseStyleList = defineStyle({
	[$bg.variable]: 'colors.white',
	[$shadow.variable]: 'shadows.md',
	color: 'inherit',
	minW: '13rem',
	py: '0.25rem',
	zIndex: 'dropdown',
	overflow: 'hidden',
	borderRadius: 'md',
	borderWidth: '1px',
	bg: $bg.reference,
	boxShadow: $shadow.reference
})

const baseStyleItem = defineStyle({
	py: '0.75rem',
	px: '1rem',
	transitionProperty: 'background',
	transitionDuration: 'ultra-fast',
	transitionTimingFunction: 'ease-in',
	_focus: {
		[$bg.variable]: 'colors.gray.100'
	},
	_hover: {
		[$bg.variable]: 'colors.gray.100'
	},
	_selected: {
		[$bg.variable]: 'colors.white',
		borderColor: 'brand.200',
		borderInlineWidth: '2px',
		borderBlockWidth: '1px',
		py: '0.6875rem',
		px: '0.875rem'
	},
	_expanded: {
		[$bg.variable]: 'colors.gray.100'
	},
	_disabled: {
		color: 'gray.200',
		cursor: 'not-allowed'
	},
	bg: $bg.reference
})

const baseStyleGroupTitle = defineStyle({
	mx: '1rem',
	my: '0.75rem',
	color: 'gray.300',
	textStyle: 'body-text-5'
})

const baseStyleIcon = defineStyle({
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	flexShrink: 0
})

const baseStyleCommand = defineStyle({
	color: 'gray.200'
})

const baseStyleDivider = defineStyle({
	border: 0,
	borderBottom: '1px solid',
	borderColor: 'gray.200',
	my: '0.5rem'
})

const baseStyleButton = defineStyle({
	transitionProperty: 'common',
	transitionDuration: 'normal'
})

const baseStyle = definePartsStyle({
	button: baseStyleButton,
	list: baseStyleList,
	item: baseStyleItem,
	groupTitle: baseStyleGroupTitle,
	icon: baseStyleIcon,
	command: baseStyleCommand,
	divider: baseStyleDivider
})

// Should we use this in theme?
export const menuTheme = defineMultiStyleConfig({
	baseStyle
})
