import { useTranslation } from 'react-i18next'
import { isRouteErrorResponse, useRouteError } from 'react-router'

import { Container, Text } from '@chakra-ui/react'

import { NotFound } from '@wwt/ui/components'

export const ErrorPage = () => {
	const { t } = useTranslation('not-found')
	const error = useRouteError()
	console.error(error)

	if (isRouteErrorResponse(error)) {
		return (
			<Container flex="1">
				<NotFound
					icon={
						<Text
							as="span"
							fontSize="7xl"
						>
							{error.status}
						</Text>
					}
					title={error.statusText}
					text={t('errorPage.text')}
					h="100dvh"
				/>
			</Container>
		)
	}

	return (
		<Container flex="1">
			<NotFound
				title={t('errorPage.title')}
				text={t('errorPage.text')}
				h="100dvh"
			/>
		</Container>
	)
}
