export const checkUrlOnValid = (url: unknown) => {
	if (typeof url !== 'string') {
		return false
	}

	let givenURL

	try {
		givenURL = new URL(url)
	} catch (error) {
		return false
	}

	return givenURL.protocol === 'http:' || givenURL.protocol === 'https:'
}
