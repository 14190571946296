// disabled here camelcase rule, this is how classes in react-day-select are named

/* eslint-disable camelcase */
import { ClassNames } from 'react-day-picker'

import {
	createMultiStyleConfigHelpers,
	cssVar,
	defineStyle
} from '@chakra-ui/react'

import { runIfFunction } from '@wwt/shared/utils'

import { buttonTheme } from './buttonTheme'

const helpers = createMultiStyleConfigHelpers([
	'container',
	'months',
	'month',
	'caption',
	'caption_label',
	'nav',
	'nav_button',
	'nav_button_previous',
	'nav_button_next',
	'table',
	'head_row',
	'head_cell',
	'row',
	'cell',
	'day',
	'day_range_start',
	'day_range_middle',
	'day_range_end',
	'day_selected',
	'day_today',
	'day_outside',
	'day_disabled',
	'day_hidden',
	'hovered_start',
	'hovered_middle',
	'hovered_end'
] satisfies (
	| keyof ClassNames
	| 'container'
	| 'month_end'
	| 'month_start'
	| 'least_busy'
	| 'average'
	| 'busiest'
	| 'hovered_start'
	| 'hovered_middle'
	| 'hovered_end'
)[])

const $cellWidth = cssVar('calendar-cell-width')

const before = defineStyle({
	content: '""',
	position: 'absolute',
	top: '0.5rem',
	right: '0',
	bottom: 'calc(100% - 2.5rem)',
	left: '0',
	bg: 'gray.100',
	zIndex: '-1'
})

const active = defineStyle({
	'&>.rdp-day_content': {
		bg: 'brand.300',
		color: 'white',
		borderColor: 'transparent'
	}
})

export const calendarTheme = helpers.defineMultiStyleConfig({
	baseStyle: props => ({
		container: {
			w: 'fit-content'
		},
		months: {
			[$cellWidth.variable]: {
				base: '2.75rem',
				md: '2.5rem',
				xl: '3.5rem'
			},
			display: 'flex',
			flexDirection: { base: 'column', md: 'row' },
			gap: { base: '2rem', xl: '5rem' },
			w: 'fit-content'
		},
		month: {
			scrollMarginTop: '1.5rem',
			maxW: { base: '19.25rem', md: '17.5rem', xl: '24.5rem' },
			'> * + *': {
				mt: { base: '1rem', xl: '1.5rem' }
			}
		},
		caption: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			position: 'relative',
			h: { md: '2.25rem' }
		},
		caption_label: {
			textStyle: 'body-text-3',
			wordSpacing: '1rem'
		},
		nav: {
			display: 'flex',
			alignItems: 'center',
			gap: '0.25rem'
		},
		nav_button: {
			...runIfFunction(buttonTheme.baseStyle, props),
			...runIfFunction(buttonTheme.variants?.['icon-alt'], {
				...props,
				colorScheme: 'brand'
			}),
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			w: '2.25rem', // 36px
			h: '2.25rem', // 36px
			outline: 'none',
			rounded: 'full',
			_disabled: {
				opacity: '0'
			}
		},
		nav_button_previous: {
			position: 'absolute',
			left: '0'
		},
		nav_button_next: {
			position: 'absolute',
			right: '0'
		},
		table: {
			width: 'full',
			borderCollapse: 'collapse'
		},
		head_row: {
			display: 'flex',
			textStyle: { base: 'body-text-7', xl: 'body-text-5' },
			gap: { xl: '0.875rem' }
		},
		head_cell: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			h: '2.5rem',
			w: $cellWidth.reference,
			maxW: '2.75rem'
		},
		row: {
			display: 'flex',
			w: 'full',
			_first: {
				mt: { base: '0.5rem', xl: '1rem' }
			}
		},
		cell: {
			h: { base: '3.75rem', md: '3.5rem', xl: '3.75rem' },
			w: $cellWidth.reference,
			p: '0',
			_last: {
				'&>.rdp-day_range_middle::before': {
					roundedRight: 'full'
				},
				'&>.rdp-day_range_start::before': {
					roundedRight: 'full'
				},
				'&>.rdp-hovered_middle::before': {
					roundedRight: 'full'
				},
				'&>.rdp-hovered_start::before': {
					roundedRight: 'full'
				}
			},
			_first: {
				'&>.rdp-day_range_middle::before': {
					roundedLeft: 'full'
				},
				'&>.rdp-day_range_end::before': {
					roundedLeft: 'full'
				},
				'&>.rdp-hovered_middle::before': {
					roundedLeft: 'full'
				},
				'&>.rdp-hovered_end::before': {
					roundedLeft: 'full'
				}
			}
		},
		day: {
			outline: 'none',
			position: 'relative',
			display: 'flex',
			justifyContent: 'center',
			textStyle: 'body-text-6',
			boxSize: 'full',
			py: '0.5rem',
			'&>.rdp-day_content': {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				rounded: 'full',
				boxSize: '2rem',
				transitionProperty: 'background color border-color',
				transitionDuration: 'normal'
			},
			_hover: {
				'&>.rdp-day_content': {
					bg: 'white',
					color: 'brand.300',
					border: '0.0625rem solid',
					borderColor: 'brand.300'
				}
			},
			_active: active,
			_after: {
				content: '""',
				position: 'absolute',
				bottom: '0.5rem',
				left: '50%',
				boxSize: '0.5rem',
				rounded: 'full',
				transform: 'translateX(-50%)',
				bg: 'gray.200',
				pointerEvents: 'none'
			}
		},
		day_selected: {
			'&>.rdp-day_content': {
				bg: 'gray.100',
				border: '0.0625rem solid',
				borderColor: 'gray.200'
			},
			_hover: {
				'&>.rdp-day_content': {
					bg: 'brand.100',
					color: 'white',
					borderColor: 'transparent'
				},
				_active: active
			}
		},
		day_range_start: {
			'&:not(.rdp-day_range_end)::before': {
				...before,
				left: '50%'
			}
		},
		day_range_middle: {
			'&>.rdp-day_content': {
				bg: 'transparent',
				borderColor: 'transparent'
			},
			_hover: {
				'&>.rdp-day_content': {
					bg: 'transparent',
					color: 'gray.500',
					border: '0.0625rem solid',
					borderColor: 'gray.500'
				},
				_active: active
			},
			_before: before
		},
		day_range_end: {
			'&:not(.rdp-day_range_start)::before': {
				...before,
				right: '50%'
			}
		},
		day_disabled: {
			// easy way to add css specificity
			'&>.rdp-day_content.rdp-day_content': {
				bg: 'transparent',
				color: 'gray.200',
				border: '0'
			},
			cursor: 'not-allowed'
		},
		month_start: {
			'&.rdp-day_range_middle::before': {
				roundedLeft: 'full'
			},
			'&.rdp-day_range_end::before': {
				roundedLeft: 'full'
			},
			'&.rdp-hovered_middle::before': {
				roundedLeft: 'full'
			},
			'&.rdp-hovered_end::before': {
				roundedLeft: 'full'
			}
		},
		month_end: {
			'&.rdp-day_range_middle::before': {
				roundedRight: 'full'
			},
			'&.rdp-day_range_start::before': {
				roundedRight: 'full'
			},
			'&.rdp-hovered_middle::before': {
				roundedRight: 'full'
			},
			'&.rdp-hovered_start::before': {
				roundedRight: 'full'
			}
		},
		least_busy: {
			_after: {
				bg: 'green.100'
			}
		},
		average: {
			_after: {
				bg: 'yellow.100'
			}
		},
		busiest: {
			_after: {
				bg: 'red.100'
			}
		},
		hovered_start: {
			'&:not(.rdp-hovered_end)::before': {
				...before,
				left: '50%'
			}
		},
		hovered_middle: {
			'&>.rdp-day_content': {
				bg: 'transparent',
				borderColor: 'transparent'
			},
			_hover: {
				'&>.rdp-day_content': {
					bg: 'transparent',
					color: 'gray.500',
					border: '0.0625rem solid',
					borderColor: 'gray.500'
				},
				_active: active
			},
			_before: before
		},
		hovered_end: {
			'&:not(.rdp-hovered_start)::before': {
				...before,
				right: '50%'
			}
		}
	}),
	variants: {},
	sizes: {},
	defaultProps: {}
})
