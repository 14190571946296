import { OffersResponseDto } from '../../autogenerated/autogeneratedTypes.ts'

// type InputForImagesValidator = Pick<
// 	OffersResponseDto,
// 	'hotelStatic' | 'roomStatic' | 'offers'
// >

export const responseContentImagesValidator = (
	responseContent: OffersResponseDto
) => {
	const offerImages = responseContent.offers?.map(offer => {
		const offerHotelStatic =
			responseContent.hotelStatic?.[offer.integrationName]?.[
				offer.integrationHotelId
			]
		const offerRoomStatic =
			responseContent.roomStatic?.[offer.integrationName]?.[
				offer.integrationHotelId
			]?.[offer.integrationRoomId]

		if (!offerHotelStatic || !offerRoomStatic) {
			throw new Error(
				'Search response validation error on get hotel static and room static data'
			)
		}

		return {
			offerId: offer.nonPersistingOfferId,
			hotelStaticImages: offerHotelStatic.images,
			roomStaticImages: offerRoomStatic.images
		}
	})

	const offersWithErrors: {
		path: string[]
		message: string
	}[] = []

	offerImages?.forEach(({ offerId, hotelStaticImages, roomStaticImages }) => {
		if (
			(!hotelStaticImages || hotelStaticImages.length === 0) &&
			(!roomStaticImages || roomStaticImages.length === 0)
		) {
			offersWithErrors.push({
				path: [`offerId: ${offerId}`, 'images'],
				message:
					'Images should be set or in roomStatic, or in hotelStatic, or in both'
			})
		}
	})

	return offersWithErrors
}
