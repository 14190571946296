import { OffersResponseDto } from '../../autogenerated/autogeneratedTypes.ts'
import { featureNamesValidator } from './featureNamesValidator'

interface OfferFeaturesForValidation {
	offerId: string
	features: Record<
		// From
		string,
		{
			name: string
			group?: { name: string }
		}[]
	>
}

export const allFeaturesNamesValidator = (
	responseContent: OffersResponseDto
) => {
	const offersFeatures: OfferFeaturesForValidation[] =
		responseContent.offers?.map(offer => {
			// TODO: Rewrite
			const offerHotelStatic =
				responseContent.hotelStatic?.[offer.integrationName][
					offer.integrationHotelId
				]
			const offerRoomStatic =
				responseContent.roomStatic?.[offer.integrationName][
					offer.integrationHotelId
				][offer.integrationRoomId]
			const offerHotelDynamic =
				responseContent.hotelDynamic?.[offer.integrationName][
					offer.integrationHotelId
				][offer.nonPersistingOfferId]
			const offerRoomDynamic =
				responseContent.roomDynamic?.[offer.integrationName][
					offer.integrationHotelId
				][offer.integrationRoomId][offer.nonPersistingOfferId]

			return {
				offerId: offer.nonPersistingOfferId,
				features: {
					hotelDynamicCheckInFeatures: offerHotelDynamic?.checkInFeatures ?? [],
					hotelStaticIncludedFeatures: offerHotelStatic?.includedFeatures ?? [],
					bookablePackagedFeatures:
						offerRoomDynamic?.bookablePackagedFeatures ?? [],
					bookableNotPackagedFeatures:
						offerRoomDynamic?.bookableNotPackagedFeatures ?? [],
					roomStaticIncludedFeatures: offerRoomStatic?.includedFeatures ?? [],
					roomDynamicCheckInFeatures: offerRoomDynamic?.checkInFeatures ?? [],
					roomDynamicIncludedFeatures: offerRoomDynamic?.includedFeatures ?? []
				}
			}
		}) ?? []

	const result: {
		message: string
	}[] = []

	offersFeatures.forEach(offer => {
		Object.entries(offer.features).forEach(([from, features]) => {
			const featuresWithFrom = features.map(feature => ({
				...feature,
				from
			}))
			const validationErrors = featureNamesValidator(featuresWithFrom)

			if (validationErrors.length > 0) {
				const addToErrorsOfferId = validationErrors.map(validationError => ({
					...validationError,
					path: [
						`from = ${from}`,
						`offerId = ${offer.offerId}`,
						...validationError.path
					]
				}))

				result.push(...addToErrorsOfferId)
			}
		})
	})

	return result
}
