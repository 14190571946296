/**
 * Change only first letter in string to upper case
 *
 * radash `capitalize` makes all other characters lowercase
 * but this function does not
 *
 * @author Ivan Shchedrovskyi
 */
export const capitalizeFirstLetter = (input: string) => {
	return input.charAt(0).toUpperCase() + input.slice(1)
}
