import { FeatureType } from '@wwt/entities/api/search-response'
import { Feature, FeatureOptions } from '@wwt/entities/search-response'
import { capitalizeFirstLetter } from '@wwt/shared/utils/capitalizeFirstLetter.ts'

import { TooltipIconType } from '../../Tooltips'
import { FeatureTooltipInfo } from '../types/FeatureTooltipInfo.ts'

/**
 * Util for generate tooltip info for feature
 *
 * @author Ivan Shchedrovskyi
 */
export const getFeatureTooltipInfo = (
	feature: Feature,
	selectedOption: FeatureOptions,
	showSelectedOption?: boolean,
	showFeatureName?: boolean
): FeatureTooltipInfo => {
	const defaultFeatureTooltip: FeatureTooltipInfo = {
		hasTooltip: false,
		tooltipTitle: '-',
		tooltipText: '-',
		tooltipImageUrl: '-'
	}

	const tooltipIconType =
		feature.type === FeatureType.checkIn
			? TooltipIconType.dollar
			: TooltipIconType.default

	if (showSelectedOption) {
		if (showFeatureName) {
			return {
				hasTooltip: Boolean(selectedOption.description || feature.description),

				tooltipTitle:
					capitalizeFirstLetter(feature.name) +
					': ' +
					capitalizeFirstLetter(selectedOption.name),
				tooltipText: selectedOption.description ?? feature.description,
				tooltipImageUrl: selectedOption.imageUrl ?? feature.imageUrl,
				tooltipIconType: tooltipIconType
			}
		}

		return {
			hasTooltip: Boolean(selectedOption.description),
			tooltipTitle: capitalizeFirstLetter(selectedOption.name),
			tooltipText: selectedOption.description,
			tooltipImageUrl: selectedOption.imageUrl,
			tooltipIconType: tooltipIconType
		}
	}

	if (showFeatureName) {
		return {
			hasTooltip: Boolean(feature.description),
			tooltipTitle: capitalizeFirstLetter(feature.name),
			tooltipText: feature.description,
			tooltipImageUrl: feature.imageUrl,
			tooltipIconType: tooltipIconType
		}
	}

	return defaultFeatureTooltip
}
