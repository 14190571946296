import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface State {
	debugMode: boolean
	sortPriorityShow: boolean
}

interface Actions {
	toggleDebugMode: () => void
	toggleSortPriorityShow: () => void
}

/**
 * Store for save local validation values
 *
 * We have two variabled: used and value
 * If used = false, app use validation value from environment variables
 * If used = true, app use validation value from this store
 *
 * @author Ivan Shchedrovskyi
 */
export const useLocalDebug = create<State & Actions>()(
	persist(
		set => ({
			debugMode: false,
			sortPriorityShow: false,

			toggleDebugMode: () =>
				set(store => ({
					...store,
					debugMode: !store.debugMode
				})),

			toggleSortPriorityShow: () =>
				set(store => ({
					...store,
					sortPriorityShow: !store.sortPriorityShow
				}))
		}),
		{
			name: 'local-debug-v2' // name of the item in the storage (must be unique)
			// by default, 'localStorage' is used
		}
	)
)
