import { ReactNode } from 'react'

import {
	Box,
	BoxProps,
	CloseButtonProps,
	HeadingProps,
	ModalCloseButton
} from '@chakra-ui/react'

import CrossIcon from '@wwt/shared/assets/icons/outline/close.svg'

interface ModalHeaderProps extends BoxProps {
	text: string
	headingProps?: HeadingProps
	closeButtonProps?: CloseButtonProps | Record<string, string>
	leftButton?: ReactNode
}

export const ModalHeader = ({
	text,
	headingProps,
	closeButtonProps,
	leftButton,
	...props
}: ModalHeaderProps) => {
	return (
		<Box
			as="header"
			w="full"
			{...props}
		>
			<Box
				display="flex"
				alignItems="center"
				position="relative"
			>
				{leftButton}
				<Box
					as="h2"
					color="black"
					textAlign="center"
					flex="1"
					textStyle="headline-2"
					lineHeight="1.2"
					px="2.5rem"
					{...headingProps}
				>
					{text}
				</Box>
				<ModalCloseButton
					position="absolute"
					top={{ base: '-0.1875rem', md: '0.5rem', xl: '0.75rem' }}
					right={{ base: '0.5rem', md: '0' }}
					padding="0"
					boxSize="1.5rem"
					background="transparent"
					_hover={{ color: 'brand.200' }}
					_active={{ color: 'brand.300' }}
					{...closeButtonProps}
				>
					<CrossIcon color="gray.300" />
				</ModalCloseButton>
			</Box>
		</Box>
	)
}
