import { type ReactNode, forwardRef } from 'react'

import { Text, type TextProps } from '@chakra-ui/react'

import { FeatureTooltipInfo } from '../types/FeatureTooltipInfo.ts'
import { type FeatureIconProps } from './FeatureIcon.tsx'
import { FeatureTooltipByTooltipInfo } from './FeatureTooltipByTooltipInfo.tsx'

interface FeatureButtonProps extends TextProps {
	basePropsForIcon: FeatureIconProps
	isExist?: boolean
	tooltipInfo: FeatureTooltipInfo
	onMouseLeave?: () => void
	onMouseEnter?: () => void
	children: ReactNode
}

export const FeatureButton = forwardRef<
	HTMLParagraphElement,
	FeatureButtonProps
>(
	(
		{
			basePropsForIcon,
			isExist,
			tooltipInfo,
			onMouseLeave,
			onMouseEnter,
			children,
			...props
		}: FeatureButtonProps,
		ref
	) => {
		return (
			<>
				<Text
					ref={ref}
					whiteSpace="normal"
					{...props}
				>
					{children}
				</Text>
				<FeatureTooltipByTooltipInfo
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					basePropsForIcon={basePropsForIcon}
					tooltipInfo={tooltipInfo}
					isExist={isExist}
				/>
			</>
		)
	}
)

FeatureButton.displayName = 'FeatureButton'
