import { type ReactNode } from 'react'

import { Box, type BoxProps, Tooltip } from '@chakra-ui/react'

interface CellContainerProps extends BoxProps {
	isHovered?: boolean
	label?: string | ReactNode
	tooltipOpen?: boolean
	children: ReactNode
}

export const CellContainer = ({
	isHovered = false,
	label = '',
	tooltipOpen = undefined,
	children,
	...props
}: CellContainerProps) => {
	return (
		<Tooltip
			label={label}
			openDelay={500}
			isOpen={tooltipOpen}
		>
			<Box
				width="full"
				height="full"
				attr-data="container"
				display="flex"
				justifyContent="center"
				alignItems="center"
				columnGap="0.2rem"
				px="1rem"
				py="0.90625rem"
				_hover={
					isHovered
						? {
								outline: '0.0625rem solid',
								outlineOffset: '-0.0625rem',
								outlineColor: 'brand.300'
						  }
						: {}
				}
				{...props}
			>
				{children}
			</Box>
		</Tooltip>
	)
}
