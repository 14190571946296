import { popoverAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
	popper: defineStyle({
		zIndex: 'tooltip'
	})
})

export const popoverTheme = defineMultiStyleConfig({
	baseStyle,
	defaultProps: {
		variant: 'default'
	}
})
