import { Box, HTMLChakraProps, forwardRef } from '@chakra-ui/react'

import { useStyles } from './useDropdown'

export type DropdownDividerProps = HTMLChakraProps<'hr'>

export const DropdownDivider = forwardRef<DropdownDividerProps, 'hr'>(
	(props, ref) => {
		const styles = useStyles()
		return (
			<Box
				as="hr"
				ref={ref}
				aria-orientation="horizontal"
				__css={styles.divider}
				{...props}
			/>
		)
	}
)
DropdownDivider.displayName = 'DropdownDivider'
