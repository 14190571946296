import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface SocketUrl {
	url: string
	description: string
}

interface State {
	socketUrls: Record<number, SocketUrl>
	selectedSocketUrlId: number
}

interface Actions {
	addSocketUrl: (id: number, socketUrl: SocketUrl) => void
	addEmptySocketUrl: () => void
	removeSocketUrl: (id: number) => void
	updateSocketUrl: (id: number, newSocketUrl: SocketUrl) => void
	getCurrentSocketUrl: () => SocketUrl
	setSelectedSocketUrlId: (id: number) => void
}

export const useAdminSocketUrl = create<State & Actions>()(
	persist(
		(set, get) => ({
			socketUrls: {
				0: {
					url: 'https://test-wwt-mock-server.onrender.com',
					description: 'Mock server'
				},
				1: {
					url: 'http://localhost:3015',
					description: 'Local server'
				}
			},
			selectedSocketUrlId: 0,

			addSocketUrl: (id, socketUrl) =>
				set(state => ({
					socketUrls: {
						...state.socketUrls,
						[id]: socketUrl
					}
				})),

			addEmptySocketUrl: () =>
				set(state => {
					const lastId = Math.max(...Object.keys(state.socketUrls).map(Number))
					return {
						socketUrls: {
							...state.socketUrls,
							[lastId + 1]: {
								url: '',
								description: ''
							}
						}
					}
				}),

			removeSocketUrl: id =>
				set(state => {
					if (Object.keys(state.socketUrls).length === 1) {
						return {}
					}
					const socketUrls = { ...state.socketUrls }
					delete socketUrls[id]
					const firstId = Math.min(...Object.keys(socketUrls).map(Number))
					return {
						socketUrls,
						selectedSocketUrlId:
							id === state.selectedSocketUrlId
								? firstId
								: state.selectedSocketUrlId
					}
				}),

			updateSocketUrl: (id, newSocketUrl) =>
				set(state => ({
					socketUrls: {
						...state.socketUrls,
						[id]: newSocketUrl
					}
				})),

			getCurrentSocketUrl: () => {
				return get().socketUrls[get().selectedSocketUrlId]
			},

			setSelectedSocketUrlId: id =>
				set(state => {
					if (!(id in state.socketUrls)) {
						return {}
					}
					return {
						selectedSocketUrlId: id
					}
				})
		}),
		{
			name: 'admin-socket-url-v1'
		}
	)
)
