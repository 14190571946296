import { Icon } from '@chakra-ui/react'

import ArrowRightIcon from '@wwt/shared/assets/icons/outline/arrow-right.svg'

export const IconRight = () => {
	return (
		<Icon
			as={ArrowRightIcon}
			boxSize="1rem"
		/>
	)
}
