import { Box } from '@chakra-ui/react'

import { TooltipBase } from '../../Tooltips'
import { FeatureTooltipInfo } from '../types/FeatureTooltipInfo.ts'
import { FeatureIconMemo, type FeatureIconProps } from './FeatureIcon.tsx'
import { FeatureTooltip } from './FeatureTooltip.tsx'

interface FeatureTooltipByTooltipInfoProps {
	tooltipInfo: FeatureTooltipInfo
	onMouseLeave?: () => void
	onMouseEnter?: () => void
	basePropsForIcon: FeatureIconProps
	isExist?: boolean
}

/**
 * Wrapper on Tooltip base and FeatureTooltip components
 * Used for show tooltipInfo
 *
 * @author Ivan Shchedrovskyi
 */
export const FeatureTooltipByTooltipInfo = ({
	tooltipInfo: {
		hasTooltip,
		tooltipImageUrl,
		tooltipTitle,
		tooltipText,
		tooltipIconType
	},
	onMouseEnter,
	onMouseLeave,
	basePropsForIcon,
	isExist
}: FeatureTooltipByTooltipInfoProps) => {
	if (!hasTooltip || !tooltipTitle) {
		return null
	}

	return (
		<Box
			onMouseLeave={onMouseLeave}
			onMouseEnter={onMouseEnter}
		>
			<TooltipBase tooltipIconType={tooltipIconType}>
				<FeatureTooltip
					title={tooltipTitle}
					text={tooltipText}
					imageUrl={tooltipImageUrl}
					iconComponent={() => (
						<FeatureIconMemo
							{...basePropsForIcon}
							color={isExist ? 'gray.500' : 'brand.100'}
						/>
					)}
				/>
			</TooltipBase>
		</Box>
	)
}
