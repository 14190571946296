import { Children, PropsWithChildren, cloneElement } from 'react'

import { useMergeRefs } from '@chakra-ui/react'

import { useDropdown } from './useDropdown'

export const DropdownAnchor = (props: PropsWithChildren<object>) => {
	// enforce a single child
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const child: any = Children.only(props.children)
	const { anchorRef, referenceRef } = useDropdown()

	return (
		<>
			{cloneElement(child, {
				...child.props,
				ref: useMergeRefs(child.ref, anchorRef, referenceRef)
			})}
		</>
	)
}
