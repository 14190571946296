import { Container } from '@chakra-ui/react'

import { NotFound } from '@wwt/ui/components'

export const NotFoundPage = () => {
	return (
		<Container flex="1">
			<NotFound py="5rem" />
		</Container>
	)
}
