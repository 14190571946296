import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface State {
	localValidationUsed: boolean
	localValidationValue: boolean
}

interface Actions {
	toggleLocalValidationUsed: () => void
	toggleLocalValidationValue: () => void
}

/**
 * Store for save local validation values
 *
 * We have two variabled: used and value
 * If used = false, app use validation value from environment variables
 * If used = true, app use validation value from this store
 *
 * @author Ivan Shchedrovskyi
 */
export const useLocalValidation = create<State & Actions>()(
	persist(
		set => ({
			localValidationUsed: false,
			localValidationValue: true,

			toggleLocalValidationUsed: () =>
				set(store => ({
					...store,
					localValidationUsed: !store.localValidationUsed
				})),

			toggleLocalValidationValue: () =>
				set(store => ({
					...store,
					localValidationValue: !store.localValidationValue
				}))
		}),
		{
			name: 'local-validation' // name of the item in the storage (must be unique)
			// by default, 'localStorage' is used
		}
	)
)
