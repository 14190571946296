import { BookablePackagedFeatureDto } from '../../autogenerated/autogeneratedTypes.ts'
import { PackagesMap } from './PackagesMap'

// TODO: REFACTOR
// COPIED FROM OFFER CONFIGURATION

/**
 * Returns the map of all existing packages;
 * each package includes features and selected options
 */
export const getPackagesMap = (
	features: BookablePackagedFeatureDto[]
): PackagesMap => {
	if (features.length === 0) {
		return {}
	}

	return features.reduce<PackagesMap>((result, feature) => {
		feature.options.forEach(option => {
			// NOTE: variable with name `package` should not exist, because it is a reserved word in JS strict mode
			option.packages.forEach(pack => {
				const packageKey = pack.bookingKey
				// if map hasn't yet this package, then create it with basic info
				const packageExistsInMap = Boolean(result[packageKey])

				if (!packageExistsInMap) {
					result[packageKey] = {
						selected: pack.isSelected,
						price: pack.price,
						features: {}
					}
				}

				result[packageKey].features[feature.name] = {
					option: option.name
				}
			})
		})

		return result
	}, {})
}
