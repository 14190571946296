import { create } from 'zustand'

import { StackedActions, StackedState } from './stackedTypes'

export const useStackedStore = create<StackedState & StackedActions>(set => ({
	stackedList: [],
	addElement: element => {
		if (!element.ref.current) {
			return
		}
		const elementHeight = element.ref.current?.clientHeight
		if (elementHeight === 0) {
			return
		}

		set(state => {
			const isElementAlreadyAdded = state.stackedList.find(
				el => el.id === element.id
			)
			if (isElementAlreadyAdded) {
				return { ...state }
			}

			// Set timeout to close element
			if (element.closeOnTimeout && element.persistTime) {
				setTimeout(() => {
					element.onClose?.()
					state.removeElement(element.id)
				}, element.persistTime)
			}

			if (element.bottomPriority) {
				const index = state.stackedList.findLastIndex(el => el.bottomPriority)
				const newStackedList = [
					...state.stackedList.slice(0, index + 1),
					element,
					...state.stackedList.slice(index + 1)
				]
				return {
					...state,
					stackedList: newStackedList
				}
			}

			if (element.topPriority) {
				return {
					...state,
					stackedList: [...state.stackedList, element]
				}
			}

			const index = state.stackedList.findIndex(el => el.topPriority)
			const newStackedList = [
				...state.stackedList.slice(0, index),
				element,
				...state.stackedList.slice(index)
			]
			return {
				...state,
				stackedList: newStackedList
			}
		})
	},
	removeElement: id => {
		set(state => {
			const newStackedList = state.stackedList.filter(
				element => element.id !== id
			)

			return {
				...state,
				stackedList: newStackedList
			}
		})
	}
}))
