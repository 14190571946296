import { Feature, FeatureOptions } from '@wwt/entities/search-response'

export const getSelectedOptionInfoFromFeature = (
	feature: Feature
): FeatureOptions => {
	if ('options' in feature) {
		if (feature.options.length === 1) {
			return feature.options[0]
		}

		const selectedOption = feature.options.find(option => option.isSelected)

		if (!selectedOption) {
			throw new Error(
				`Feature ${feature.name}, type = ${feature.type} has no selected option`
			)
		}

		return selectedOption
	}

	return feature.option
}
