import { Box, Flex, Image, Text } from '@chakra-ui/react'

interface TooltipWithPicture {
	img: string
	title: string
	text: string
}

export const TooltipWithPicture = ({
	img,
	text,
	title
}: TooltipWithPicture) => {
	return (
		<Flex
			flexDirection="column"
			gap="1rem"
		>
			<Box
				borderRadius="xs"
				overflow="hidden"
				objectFit="cover"
				maxW="18.75rem"
				maxH="18.75rem"
			>
				<Image
					src={img}
					alt={title + ' image'}
					w="100%"
					h="100%"
				/>
			</Box>
			<Flex
				flexDirection="column"
				gap="0.5rem"
			>
				<Box textStyle="body-text-2">{title}</Box>
				<Text
					textAlign="start"
					textStyle="body-text-7"
					whiteSpace="pre-line"
				>
					{text}
				</Text>
			</Flex>
		</Flex>
	)
}
