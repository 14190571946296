import { Button, ButtonProps, SystemProps, forwardRef } from '@chakra-ui/react'

import { DropdownIcon } from './DropdownIcon'
import { useStyles } from './useDropdown'

type DropdownItemProps = ButtonProps & {
	/**
	 * The icon to render before the menu item's label.
	 * @type React.ReactElement
	 */
	leftIcon?: React.ReactElement
	/**
	 * The spacing between the icon and menu item's label.
	 * @type SystemProps["mr"]
	 */
	iconSpacing?: SystemProps['mr']
	/**
	 * The icon to render after the menu item's label.
	 * @type React.ReactElement
	 */
	rightIcon?: React.ReactElement
	/**
	 * Disables the menu item.
	 * @type boolean
	 */
	isDisabled?: boolean
}

export const DropdownItem = forwardRef<DropdownItemProps, 'button'>(
	({ leftIcon, rightIcon, iconSpacing = '1rem', children, ...props }, ref) => {
		const styles = useStyles()

		const shouldWrap = leftIcon || rightIcon

		const _children = shouldWrap ? (
			<span style={{ pointerEvents: 'none', flex: 1 }}>{children}</span>
		) : (
			children
		)

		return (
			<Button
				as="li"
				ref={ref}
				__css={styles.item}
				{...props}
			>
				{leftIcon && (
					<DropdownIcon
						fontSize="0.8rem"
						marginEnd={iconSpacing}
					>
						{leftIcon}
					</DropdownIcon>
				)}
				{_children}
				{rightIcon && (
					<DropdownIcon
						fontSize="0.8rem"
						marginStart="auto"
					>
						{rightIcon}
					</DropdownIcon>
				)}
			</Button>
		)
	}
)
DropdownItem.displayName = 'DropdownItem'
