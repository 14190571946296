import { useCallback, useEffect, useRef, useState } from 'react'

export const useCellRef = () => {
	const ref = useRef<HTMLDivElement>(null)
	const [tooltipOpen, setTooltipOpen] = useState<boolean | undefined>(false)

	const checkOverflow = useCallback(
		(el: HTMLDivElement) => {
			setTooltipOpen(el.scrollHeight > el.offsetHeight ? undefined : false)
		},
		[ref, setTooltipOpen]
	)

	useEffect(() => {
		const el = ref.current
		if (!el) return

		checkOverflow(el)

		const resizeObserver = new ResizeObserver(() => {
			checkOverflow(el)
		})
		resizeObserver.observe(el)

		return () => {
			resizeObserver.disconnect()
		}
	}, [checkOverflow])

	return { tooltipOpen, ref }
}
