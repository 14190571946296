import {
	CaptionDropdowns,
	CaptionLabel,
	CaptionNavigation,
	CaptionProps,
	useDayPicker
} from 'react-day-picker'

import { Box } from '@chakra-ui/react'
import { format } from 'date-fns'

export const Caption = (props: CaptionProps) => {
	const { classNames, disableNavigation, styles, captionLayout, components } =
		useDayPicker()

	const CaptionLabelComponent = components?.CaptionLabel ?? CaptionLabel

	let caption: JSX.Element
	if (disableNavigation) {
		caption = (
			<CaptionLabelComponent
				id={props.id}
				displayMonth={props.displayMonth}
			/>
		)
	} else if (captionLayout === 'dropdown') {
		caption = (
			<CaptionDropdowns
				displayMonth={props.displayMonth}
				id={props.id}
			/>
		)
	} else if (captionLayout === 'dropdown-buttons') {
		caption = (
			<>
				<CaptionDropdowns
					displayMonth={props.displayMonth}
					displayIndex={props.displayIndex}
					id={props.id}
				/>
				<CaptionNavigation
					displayMonth={props.displayMonth}
					displayIndex={props.displayIndex}
					id={props.id}
				/>
			</>
		)
	} else {
		caption = (
			<>
				<CaptionLabelComponent
					id={props.id}
					displayMonth={props.displayMonth}
					displayIndex={props.displayIndex}
				/>
				<CaptionNavigation
					displayMonth={props.displayMonth}
					id={props.id}
				/>
			</>
		)
	}

	return (
		<Box
			id={`rdp-${format(props.displayMonth, 'M-y')}`}
			className={classNames.caption}
			style={styles.caption}
		>
			{caption}
		</Box>
	)
}
