import { type ReactNode, forwardRef } from 'react'

import { Button, type ButtonProps, HStack, Text } from '@chakra-ui/react'

import { FeatureTooltipInfo } from '../types/FeatureTooltipInfo.ts'
import { FeatureIconMemo, type FeatureIconProps } from './FeatureIcon.tsx'
import { FeatureTooltipByTooltipInfo } from './FeatureTooltipByTooltipInfo.tsx'

interface FeatureClickableButtonProps extends ButtonProps {
	basePropsForIcon: FeatureIconProps
	isExist?: boolean
	onClick?: () => void
	disableIcon?: boolean
	tooltipInfo: FeatureTooltipInfo
	children: ReactNode
}

export const FeatureClickableButton = forwardRef<
	HTMLParagraphElement,
	FeatureClickableButtonProps
>(
	(
		{
			basePropsForIcon,
			isExist,
			onClick,
			disableIcon,
			noOfLines,
			textAlign,
			tooltipInfo,
			children,
			...props
		}: FeatureClickableButtonProps,
		ref
	) => {
		return (
			<HStack
				w="full"
				gap="0.5rem"
			>
				<Button
					variant="link"
					color="primary.100"
					leftIcon={
						!disableIcon ? (
							<FeatureIconMemo
								{...basePropsForIcon}
								color={isExist ? 'primary.100' : 'brand.100'}
							/>
						) : undefined
					}
					iconSpacing="0.75rem"
					_hover={{ bg: 'none' }}
					onClick={onClick}
					textDecorationThickness="0.0625rem"
					{...props}
				>
					<Text
						ref={ref}
						noOfLines={noOfLines}
						whiteSpace="normal"
						textAlign={textAlign}
					>
						{children}
					</Text>
				</Button>

				<FeatureTooltipByTooltipInfo
					basePropsForIcon={basePropsForIcon}
					tooltipInfo={tooltipInfo}
					isExist={isExist}
				/>
			</HStack>
		)
	}
)

FeatureClickableButton.displayName = 'FeatureClickableButton'
