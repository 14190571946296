import { Box } from '@chakra-ui/react'

import { SortDebugValue } from '@wwt/core/admin'
import { Feature, FeatureOptions } from '@wwt/entities/search-response'
import { capitalizeFirstLetter } from '@wwt/shared/utils/capitalizeFirstLetter.ts'

export const useFeatureLabel = (
	selectedOption: FeatureOptions,
	feature: Feature,
	showSelectedOption?: boolean,
	showFeatureName?: boolean
) => {
	if (!showSelectedOption && !showFeatureName) {
		return (
			<>
				{'-'}: {'-'}
			</>
		)
	}

	const FeaturePriorityDebug = () => (
		<SortDebugValue
			integrationPriority={feature.integrationPriority}
			wwtPriority={feature.wwtPriority}
		/>
	)

	const OptionPriorityDebug = () => (
		<SortDebugValue
			integrationPriority={selectedOption.integrationPriority}
			wwtPriority={selectedOption.wwtPriority}
		/>
	)

	if (showSelectedOption) {
		if (showFeatureName) {
			return (
				<Box>
					<Box display="inline">{capitalizeFirstLetter(feature.name)}</Box>
					<FeaturePriorityDebug />
					<Box display="inline">
						{': '}
						{capitalizeFirstLetter(selectedOption.name)}
					</Box>
					<OptionPriorityDebug />
				</Box>
			)
		}

		return (
			<Box display="inline">
				<Box display="inline">{capitalizeFirstLetter(selectedOption.name)}</Box>
				<OptionPriorityDebug />
			</Box>
		)
	}

	return (
		<Box display="inline">
			<Box display="inline">{capitalizeFirstLetter(feature.name)}</Box>
			<FeaturePriorityDebug />
		</Box>
	)
}
