import { BookablePackagedFeatureDto } from '../../autogenerated/autogeneratedTypes.ts'
import { getPackagesMap } from './getPackagesMap'

export const packageMapValidation = (
	features: BookablePackagedFeatureDto[]
) => {
	const packagesMap = getPackagesMap(features)
	const appFeatureNames = features.map(feature => feature.name)

	const result: {
		message: string
		path?: string[]
	}[] = []

	Object.entries(packagesMap).forEach(([packageKey, packageData]) => {
		const packageFeatureNames = Object.keys(packageData.features)

		for (const featureName of appFeatureNames) {
			if (!packageFeatureNames.includes(featureName)) {
				result.push({
					message: `Package "${packageKey}" has not option in feature "${featureName}"`,
					path: [`packageKey: ${packageKey}`, `featureName: ${featureName}`]
				})
			}
		}
	})

	return result
}
