import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const baseStyle = defineStyle({
	placement: 'auto'
})

const variantSolid = defineStyle({
	bg: 'white',
	textStyle: 'body-text-7',
	color: 'gray.400',
	borderWidth: '0.0625rem',
	borderRadius: '1rem',
	borderColor: 'gray.200',
	p: '1rem',
	boxShadow: '0rem 0.125rem 0.75rem 0rem #00000024'
})

const variants = {
	solid: variantSolid
}
export const tooltipTheme = defineStyleConfig({
	baseStyle,
	variants,
	defaultProps: {
		variant: 'solid'
	}
})
