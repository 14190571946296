import { omit } from 'radash'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface ServerUrl {
	url: string
	description: string
}

interface State {
	/**
	 * Map of <id, serverUrl>
	 */
	serverUrls: Record<number, ServerUrl>
	/**
	 * Id of selected server url
	 */
	selectedServerUrlId: number
}

interface Actions {
	/**
	 * Add new server url
	 * @param id {number} - Id of new server url
	 * @param url - Server url
	 */
	addServerUrl: (id: number, serverUrl: ServerUrl) => void
	/**
	 * Create new empty server url
	 */
	addEmptyServerUrl: () => void
	/**
	 * Remove server url by id
	 * @param id {number} - Id of server url for remove
	 *
	 * **If it last server url - don't remove it**
	 */
	removeServerUrl: (id: number) => void
	/**
	 * Update server url by id
	 * @param id {number} - Id of server url for update
	 * @param newUrl - New url string
	 */
	updateServerUrl: (id: number, newServerUrl: ServerUrl) => void
	/**
	 * Get current server url for requests
	 */
	getCurrentServerUrl: () => ServerUrl
	/**
	 * Set selected server url id
	 * @param id {number} - Id of server url for select
	 */
	setSelectedServerUrlId: (id: number) => void
}

/**
 * Store for save server urls and selected server url id
 *
 * @author Ivan Shchedrovskyi
 */
export const useAdminServerUrl = create<State & Actions>()(
	persist(
		(set, get) => ({
			serverUrls: {
				0: {
					url: 'https://dev.winwin.travel',
					description: 'Backend staging'
				},
				1: {
					url: 'https://demo.winwin.travel',
					description: 'Backend release'
				},
				2: {
					url: 'http://localhost:8080',
					description: 'Local backend'
				},
				3: {
					url: 'http://127.0.0.1:3658/m1/687480-660551-default',
					description: 'ApiDog local wwt dev'
				},
				4: {
					url: 'https://mock.apidog.com/m1/687480-660551-default',
					description: 'ApiDog cloud wwt dev'
				},
				5: {
					url: 'http://127.0.0.1:3658/m1/688237-661332-default',
					description: 'ApiDog local wwt demo'
				},
				6: {
					url: 'https://mock.apidog.com/m1/688237-661332-default',
					description: 'ApiDog cloud wwt demo'
				}
			},
			selectedServerUrlId: 3,

			addServerUrl: (id, serverUrl) =>
				set(state => {
					return {
						serverUrls: {
							...state.serverUrls,
							[id]: serverUrl
						}
					}
				}),
			addEmptyServerUrl: () =>
				set(state => {
					const lastId = Math.max(...Object.keys(state.serverUrls).map(Number))

					return {
						serverUrls: {
							...state.serverUrls,
							[lastId + 1]: {
								url: import.meta.env.VITE_API_URL_REMOTE,
								description: ''
							}
						}
					}
				}),
			removeServerUrl: (id: number) =>
				set(state => {
					if (Object.keys(state.serverUrls).length === 1) {
						return {}
					}

					const serverUrls = omit(state.serverUrls, [id])

					const firstId = Math.min(...Object.keys(serverUrls).map(Number))

					return {
						serverUrls,
						selectedServerUrlId:
							id === state.selectedServerUrlId
								? firstId
								: state.selectedServerUrlId
					}
				}),
			updateServerUrl: (id, newServerUrl) =>
				set(state => {
					const serverUrls = structuredClone(state.serverUrls)
					serverUrls[id] = newServerUrl

					return {
						serverUrls
					}
				}),
			getCurrentServerUrl: () => {
				return get().serverUrls[get().selectedServerUrlId]
			},
			setSelectedServerUrlId: (id: number) =>
				set(state => {
					if (!(id in state.serverUrls)) {
						return {}
					}

					return {
						selectedServerUrlId: id
					}
				})
		}),
		{
			name: 'admin-server-url-v2' // name of the item in the storage (must be unique)
			// by default, 'localStorage' is used
		}
	)
)
