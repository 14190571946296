import { ReactNode, useRef } from 'react'

import {
	ThemingProps,
	useDisclosure,
	useMultiStyleConfig,
	usePopper
} from '@chakra-ui/react'

import { DropdownContext, StylesProvider } from './components/useDropdown'
import { DropdownProps as BasicProps } from './types'

type DropdownProps = BasicProps &
	ThemingProps<'Dropdown'> & {
		children?: ReactNode
	}

// shadcn inspired components
export const Dropdown = ({ children, ...props }: DropdownProps) => {
	const styles = useMultiStyleConfig('Dropdown', props)
	const anchorRef = useRef<HTMLDivElement>(null)
	const { isOpen, onClose } = useDisclosure(props)

	const popper = usePopper({
		flip: false,
		matchWidth: true,
		placement: 'bottom',
		offset: [0, 0],
		enabled: isOpen
	})

	return (
		<DropdownContext.Provider value={{ anchorRef, isOpen, onClose, ...popper }}>
			<StylesProvider value={styles}>{children}</StylesProvider>
		</DropdownContext.Provider>
	)
}
