import { group } from 'radash'

export const featureNamesValidator = <
	T extends { name: string; group?: { name: string }; from?: string }
>(
	features: T[] = []
) => {
	const groupedFeaturesByGroupName = group(
		features,
		(feature: T) => feature.group?.name ?? 'null'
	)

	const result: {
		message: string
		path: string[]
	}[] = []

	Object.entries(groupedFeaturesByGroupName).forEach(
		([group, featuresInGroup]) => {
			if (!featuresInGroup) {
				return
			}

			const cache: Record<string, number> = {}

			for (const feature of featuresInGroup) {
				if (feature.name in cache && cache[feature.name] > 0) {
					result.push({
						message: `In group ${group} not all features has unique name`,
						path: [
							`Feature from: ${feature.from}`,
							`Feature name: ${feature.name}`
						]
					})
					return
				}

				cache[feature.name] = 1
			}
		}
	)

	return result
}
