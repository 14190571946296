import React from 'react'
import ReactDOM from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { RouterProvider } from 'react-router-dom'

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'

import '@wwt/shared/i18n'
import { ThemeProvider } from '@wwt/ui/theme'

import { persistOptions, queryClient } from './query.ts'
import { router } from './router'

if (import.meta.env.MODE !== 'development') {
	const tagManagerArgs = {
		gtmId: import.meta.env.VITE_GOOGLE_ANALYTICS_TAG_MANAGER
	}

	TagManager.initialize(tagManagerArgs)
}

// eslint-disable-next-line
ReactDOM.createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<PersistQueryClientProvider
			client={queryClient}
			persistOptions={persistOptions}
		>
			<ThemeProvider>
				<RouterProvider router={router} />
			</ThemeProvider>
			<ReactQueryDevtools initialIsOpen={false} />
		</PersistQueryClientProvider>
	</React.StrictMode>
)
