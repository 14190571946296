export const typography = {
	fonts: {
		body: `'Inter Variable', sans-serif`,
		heading: `'Inter Variable', sans-serif`
	},
	fontSizes: {
		xxs: '0.625rem', // 10px
		xs: '0.75rem', // 12px
		sm: '0.875rem', // 14px
		md: '1rem', // 16px
		lg: '1.25rem', // 20px
		xl: '1.5rem', // 24px
		'2xl': '1.75rem', // 28px
		'3xl': '2rem', // 32px
		'4xl': '2.25rem', // 36px
		'5xl': '2.5rem', // 40px
		'6xl': '3rem' // 48px
	},
	lineHeights: {
		base: 1.21
	}
}
