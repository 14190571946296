import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { Integrations } from '@wwt/shared/config'

interface State {
	integrations: string[]
}

interface Actions {
	setIntegrations: (integrations: string[]) => void
}

/**
 * Store for save integration with opportunity edit in /admin page
 *
 * @author Ivan Shchedrovskyi
 */
export const useLocalIntegration = create<State & Actions>()(
	persist(
		set => ({
			integrations: [Integrations.HOTELBEDS],

			setIntegrations: (integrations: string[]) =>
				set(store => ({
					...store,
					integrations: integrations
				}))
		}),
		{
			name: 'admin-integrations' // name of the item in the storage (must be unique)
			// by default, 'localStorage' is used
		}
	)
)
